@import './exhibitor';

.exhibitor {
  .andes-carousel-snapped {
    height: 338px;

    &__container--content {
      .andes-carousel-snapped {
        &__control {
          height: 80px;
          width: 40px;
          border-radius: 4px;

          /* stylelint-disable-next-line max-nesting-depth */
          &--next {
            right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          /* stylelint-disable-next-line max-nesting-depth */
          &--previous {
            left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    &__pagination {
      &--position-bottom {
        bottom: 80px;
      }
    }
  }

  .banner-re__img {
    background-image: url('https://www.portalinmobiliario.com/static_assets/home-assets/desktop_banner.png');
    background-size: cover;
    background-position: center;
    height: 80px;
  }

  @media (width <= 1023px) {
    height: 227px;
  }
}

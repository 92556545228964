.recommendations {
  @import './seeds/desktop';

  .andes-carousel-snapped {
    overflow-x: clip;

    &__container {
      width: 1200px;
      min-width: 1200px;

      &.andes-carousel-snapped__container--content {
        margin-left: $andes-spacing-8;
      }
    }
  }

  .slick-list {
    @include item-container-setup;

    white-space: nowrap;
  }

  .ui-item {
    white-space: normal;
  }

  .ui-item:hover,
  .ui-item::after {
    box-shadow: 0 7px 7px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  }

  .carousel-container {
    .prev-button,
    .next-button {
      top: 120px;
    }
  }
}

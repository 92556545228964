.exhibitor {

  .banner-re__container {
    width: 100%;
  }

  .carousel-container {
    position: relative;
  }

  .andes-carousel-snapped {
    height: 338px;

    &__container--content {
      .andes-carousel-snapped {
        &__control {
          height: 80px;
          width: 40px;
          border-radius: 4px;

          /* stylelint-disable-next-line max-nesting-depth */
          &--next {
            right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          /* stylelint-disable-next-line max-nesting-depth */
          &--previous {
            left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }

    &__pagination {
      &--position-bottom {
        left: 0;
        right: 0;
        width: auto;
        position: absolute;
        margin: 0;
        height: 0;
      }
    }
  }
}

.exhibitor-item {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100vw;

  img {
    flex: 0 0 auto;
  }
}

.section-header {
  display: block;
  line-height: 1.2;
  align-items: center;

  h2 {
    color: rgb(0 0 0 / 80%);
  }

  a,
  .ui-link {
    align-self: baseline;
    color: $blue;
    font-size: 16px;
    margin: auto 0 2px;
    text-decoration: none;
  }

  .ui-link {
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    font-family: inherit;
    padding: 0;
  }

  .ui-link-button {
    outline: 0;
  }

  h2 + a,
  h2 + .ui-link {
    margin-left: 16px;
  }
}

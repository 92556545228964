@import '~@andes/money-amount/index';
@import './item-bookmark';
@import './item-price';
@import './item-shipping';
@import './item-title';
@import './item-review';
@import './item-states';
@import './item-image';

.ui-item {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 -1px 2px 0 rgb(0 0 0 / 10%);
  display: inline-flex;
  position: relative;
  text-decoration: none;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0 8px 16px;
  z-index: 1;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &__content {
    position: relative;
    border-top: 1px solid rgb(51 51 51 / 10%);
    padding: 16px 24px 24px;
  }
}

// Cases map.
$cases: (
  'default': $item-max-height - 0,
  'withPriceContextMessage': $item-max-height + 0,
  'withPriceContextMessageNoHover': $item-min-height + 0,
);

// get height by case function.
@function get-height-by-case($case) {
  // check for keys.
  @if not map-has-key($cases, $case) {
    @warn "Key `#{$case}` not found in $cases map.";
  }

  @return map-get($cases, $case);
}

// Height mixin
@mixin set-height($case) {
  height: get-height-by-case($case);
}

.ui-item {
  transition: height $transition-duration $transition-timing-function;
  position: absolute;
  left: 0;
  right: 0;

  &__title,
  &__discount-price.price-tag,
  &__action {
    opacity: 0;
    visibility: hidden;

    // No delay to be hidden during collapsing.
    transition: visibility 0s;
    transition-property: opacity, top;
  }

  &__price-block {
    margin-top: -2px;
    transition: margin-top $transition-duration $transition-timing-function;
  }

  &.with-price-context-message {
    @include set-height(withPriceContextMessageNoHover);
  }

  &:hover {
    @include set-height(default);

    &.with-price-context-message {
      @include set-height(withPriceContextMessage);
    }

    .ui-item__title,
    .ui-item__discount-price,
    .ui-item__action {
      opacity: 1;
      visibility: visible;

      // Delay 0.1 seconds before becoming visible.
      transition: visibility $transition-duration $transition-visibility-delay;
    }
  }
}

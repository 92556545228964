@import './item';
@import './item-animation';

.ui-item {
  @include item-size($item-width, $item-min-height);

  &__top-title {
    margin-top: 8px;
  }

  &__attributes {
    margin-top: -4px;
    color: #666;
  }

  &__price-block {
    margin-top: -2px;
  }

  &.price-digits-6 {
    @include price-digits(24px);

    .ui-item__discount-text {
      vertical-align: 2px;
    }
  }

  &.price-digits-7 {
    @include price-digits(22px);

    .ui-item__discount-text {
      font-size: 14px;
    }
  }

  &.price-digits-6,
  &.price-digits-7 {
    .ui-item {
      &__content {
        padding: 24px 10px 14px 24px;
      }
    }
  }

  &.price-digits-7,
  &.price-digits-8 {
    .price-tag-symbol,
    .price-tag-fraction,
    .price-tag-decimal-separator,
    .price-tag-cents {
      padding-bottom: 2px;
    }
  }

  .ui-item__price {
    line-height: 1em;
  }

  .price-tag-symbol {
    margin-right: 0.2em;
  }

  &__price_context_message {
    font-size: 12px;
    color: rgb(0 0 0 / 80%);
    display: block;
    height: 16px;
  }

  &__middle-title {
    @include titles;

    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bottom-title {
    @include titles;

    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 12px;
    }
  }
}

.ui-item:hover {
  border-radius: 4px;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 7%), 0 20px 25px 0 rgb(0 0 0 / 15%);

  .ui-item__middle-title {
    display: block;
  }
}

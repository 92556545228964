@import './discovery';

.discovery {
  .advertising {
    width: 582px;

    &:hover {
      @include box-hover-shadow;

      .advertising__info__button {
        background-color: $link-hover;
      }
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &__image {
      min-width: 290px;
      height: 250px;
    }

    &__info {
      flex: 1 0 auto;
      margin-left: 32px;

      &__title {
        font-size: 12px;
        letter-spacing: 4px;
      }

      &__text {
        font-size: 32px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &__button {
        font-size: 16px;
        width: 109px;

        &:hover {
          background-color: $link-hover;
        }
      }
    }

    &:first-child {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  @media screen and (width <= 769px) {
    .advertising {
      max-width: 357px;
    }

    .advertising__image {
      margin-left: -160px;
    }

    .advertising__info__title {
      width: 100px;
    }
  }
}

@import './squared-item.medium';

$size: 184px;

.squared-item {
  display: block;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 4px;
  width: $size;
  height: $size;

  .lazy-load {
    background-color: #fff;
    border-radius: 4px;
  }

  &__wrapper {
    width: $size;
    height: $size;
    margin: 8px;
  }

  &:hover {
    @include box-hover-shadow;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  &__content {
    display: none;
    position: relative;
    border-top: 1px solid rgb(51 51 51 / 10%);
    padding: 16px 24px 24px;
  }

  &__title {
    color: #666;
    margin-top: 12px;
    max-height: 2.8em;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
}

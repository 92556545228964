.official-stores {
  $height: 255px;

  &-slide {
    height: $height;
    box-shadow: 0 1px 1px 0 $andes-gray-100, 0 -1px 2px 0 $andes-gray-100;

    &:hover {
      @include box-hover-shadow;

      .official-stores-slide-action a {
        color: $blue;
      }
    }

    &.is-link {
      outline: none;
      cursor: pointer;
    }

    &-background {
      height: 95px;
      margin-bottom: -40px;
      overflow: hidden;
      text-align: center;

      img {
        display: inline-block;
        width: auto;
        height: 100%;
      }
    }

    &-logo {
      background-color: $andes-white;
      border-radius: 8px;
      box-shadow: 0 2px 15px 0 $andes-gray-100;
      height: 80px;
      margin: 0 auto;
      overflow: hidden;
      position: relative; // makes it appear above its background
      width: 80px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &-title {
      color: #333;
      font-size: $font-size-24;
      font-weight: $font-weight-semibold;
      text-align: center;
      margin: 16px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 35px;
      padding: 0 16px;
    }

    &-items {
      text-align: center;
      height: 74px;
    }

    &-item {
      border: solid 1px #eee;
      border-radius: 8px;
      display: inline-block;
      height: 70px;
      margin: 3px;
      overflow: hidden;
      width: 70px;

      img {
        height: 70px;
        width: 70px;
      }
    }

    &-action {
      text-align: center;
      margin-top: 22px;

      a {
        color: #999;
        font-size: 15px;
        font-weight: $font-weight-semibold;
        text-decoration: none;
      }
    }
  }
}

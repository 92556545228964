@import './cities';

$cities-per-row: 7;
$city-size: 170px;
$city-hover-background: $andes-blue-500;
$filler-color: #eae6e6;

.cities {
  .andes-carousel-snapped__container {
    width: 1200px;
    min-width: 1200px;
  }

  .container {
    padding-right: 6px;
  }

  h2 {
    width: 100%;
  }

  .wrapper {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
    height: 2 * $city-size;
    margin-left: 7px;
  }

  .city,
  .filler {
    height: $city-size;
    outline: 1px solid $filler-color;
  }

  .city {
    position: relative;
    text-align: center;
    z-index: 1;

    .city__image {
      width: 100%;
      height: 100%;
      z-index: -1;
      position: relative;
    }

    .city__shadow {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgb(0 0 0 / 20%);
    }

    .city__title {
      position: absolute;
      width: 100%;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $andes-white;
      font-family: $font-family-primary;
      font-size: 20px;
      font-weight: $font-weight-semibold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
    }

    &-column {
      height: 2 * $city-size;
      width: $city-size;
      float: left;
    }

    &:hover {
      .city__shadow {
        background-color: rgb(52 131 250 / 80%);
      }

      @media (width <= 1196px) {
        p {
          padding-right: 1px;
        }
      }
    }
  }

  .filler {
    background-color: $filler-color;
  }
}

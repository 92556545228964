$andes-theme: 'mercadolibre';

@import '~@andes/common/variables';

$blue: #3483fa;
$font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', helvetica, roboto, arial, sans-serif;
$primary-font-color: #000;
$secondary-font-color: #8c8c8c;
$font-weight-bold: 600;

// Carousel
$image-border-radius: 4px;
$sub-title: 20px;

// Links
$link-hover: #1259c3;

// Home
$light-gray: #ddd;
$dark-gray: #666;
$font-small: 14px;
$home-background: #ededed;
$section-separator: 15px;
$timeline: #dcdcdc;
$medium-font: 24px;

// Item
$item-info-color: rgb(51 51 51);
$item-info-background: rgb(255 255 255 / 70%);
$item-min-height: 360px; // item collapsed
$item-max-height: 414px; // item expanded
$item-height-mobile: 269px;
$font-regular: 300;
$font-size-mid: 20px;
$item-shadow: 32px;
$item-width: 224px;

// Categories
$border-col-categories: #d7d7d7;
$item-margin: 36px;
$subtitle-color: #333;

// Navigation
$user-background: #f0f0f0;
$font-big: 28px;
$font-your-history: 26px;
$font-tiny: 10px;
$font-title: 16px;
$title-color: #1ac2b0;
$subtitle-history-color: #999;
$my-account-color: #3483fa;
$navigation-background: $home-background;

// Transitions
$transition-duration: 0.1s;
$transition-timing-function: ease-out;
$transition-visibility-delay: 0.1s;

// Table
$table-border-color: rgb(0 0 0 / 10%);

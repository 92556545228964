.advertising-billboard {
  .billboard {
    width: 1180px;
    height: 250px;
  }

  &.loaded .caption {
    display: block;
  }

  .caption {
    display: none;
    color: #999;
    font-size: 14px;
    margin-top: 8px;
    text-align: right;
  }
}

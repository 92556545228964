.publish-with-us {
  background-color: $andes-white;
  margin-top: 60px;

  .publish-with-us__row-container {
    padding: 75px 130px;

    .publish-with-us__main {
      width: 33%;
      float: left;
      text-align: center;

      img {
        float: left;
        width: 56px;
        height: 56px;
      }

      h2 {
        float: left;
        font-family: $font-family-primary;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #4b4b4b;
        max-width: 180px;
        padding: 0 28px;
        margin: 0;
        text-align: left;
      }
    }

    .publish-with-us__message {
      width: 33%;
      float: left;
      text-align: center;

      p {
        padding: 0;
        margin: 0;
        font-family: $font-family-primary;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #999;

        a {
          color: $andes-blue-500;
          text-decoration: none;
        }
      }
    }

    .publish-with-us__action {
      width: 33%;
      float: left;
      text-align: center;
      vertical-align: baseline;

      .publish-with-us__action__btn {
        float: right;
        font-family: $font-family-primary;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: $andes-blue-500;
        border: 1px solid $andes-blue-500;
        border-radius: 4px;
        padding: 14px 32px;
        text-decoration: none;
      }
    }
  }
}

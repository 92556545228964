.advice {
  &__carousel {
    width: 1200px;
    max-width: 1200px;
  }

  .andes-carousel-snapped {
    &__slide {
      img {
        height: 150px;
      }
    }
  }
}

.ui-item:hover {
  z-index: 2;
  text-decoration: none;
}

.ui-item::after {
  content: '';
  pointer-events: none;
  position: absolute;
  border-radius: 4px;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  opacity: 0;
  top: 0;
  transition: opacity 0.18s ease-out;
  will-change: opacity;
}

.ui-item:hover::after {
  opacity: 1;
}

.ui-item__image-container {
  overflow: hidden;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ui-item__image {
  display: block;
  object-fit: cover;
}

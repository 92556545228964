@import './official-stores';
@import './slide-desktop';

.official-stores {
  $height: 255px;

  .andes-carousel-snapped {
    &__container {
      width: 1198px;
      min-width: 1198px;
      margin-left: $andes-spacing-8;
      margin-right: $andes-spacing-8;
    }

    &__slide {
      max-width: 284px !important;
    }
  }

  &:nth-child(2n) {
    background-image: linear-gradient(0, #e6e6e6, hsl(0deg 0% 100% / 0%) 100%);
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .section-header {
    a {
      font-weight: $font-weight-regular;
    }
  }

  .carousel-container {
    min-width: 870px;
    width: 100%;

    .prev-button,
    .next-button {
      top: $andes-spacing-8 * 12;
    }
  }

  .container {
    padding-bottom: 40px;
  }

  .slick-list {
    box-sizing: content-box;
    height: $height + 2px;
    padding-bottom: $andes-spacing-16 - 2;
    margin-bottom: ($andes-spacing-16 - 2) * -1;
  }

  .slick-slide {
    width: 300px;
  }
}

@import '@andes/button/lib/styles/modifier/quiet';
@import '~@andes/switch/index';
@import 'vis-faceted-search/src/desktop/index';

.main-title {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0 0;
  text-align: center;

  .main-title__text {
    margin: 0 0 0 8px;
    font-family: $font-family-primary;
    font-size: 28px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
    width: 100%;
  }
}

.search-by-code,
.search-bar {
  position: relative;
  border-radius: 6px;
  background-color: rgb(237 237 237 / 70%);
  margin: -40px auto 0;
  padding: 12px;
}

.search-by-code {
  max-width: 1200px;
}

.search-bar {
  max-width: 1200px;
}

.searchbox {
  background-image: linear-gradient(
    to right,
    $andes-white,
    $andes-white,
    $andes-white,
    $andes-white,
    $andes-white,
    $andes-white,
    $andes-white,
    $andes-white,
    $andes-blue-500
  ) !important;
  margin-top: 0 !important;

  .searchbox-dropdown__content {
    text-align: left;
  }

  .searchbox-button-container {
    .searchbox-button {
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $andes-blue-600;
      }

      &:active {
        background-color: $andes-blue-700;
      }

      span.searchbox-button__content {
        display: flex;
      }
    }
  }

  &-checkbox__content {
    margin-left: $andes-spacing-8;
  }
}

.searchbox-autocomplete-container .andes-dropdown.searchbox-autocomplete-dropdown {
  top: $andes-spacing-48;
}

html,
body {
  height: 100%;
}

.search-menu {
  max-width: 1200px;
  margin: 0 auto;
  height: 36px;
  top: -2px;
  position: relative;

  span {
    padding: 8px 16px;
    color: $blue;
    cursor: pointer;
    display: block;
    overflow: hidden;
    float: left;
    height: 20px;
    outline: none;
  }

  span.divider {
    border-left: 1px solid rgb(151 151 151 / 50%);
    height: 50%;
    margin-top: 10px;
    padding: 0;
  }

  span.divider:last-child {
    display: none;
  }
}

@keyframes search-by-code-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes search-by-code-hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes search-bar-hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes search-bar-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.search-by-code.open {
  animation: 1s linear search-by-code-show forwards;
  animation-iteration-count: 1;
}

.search-by-code.close {
  animation: 1s linear search-by-code-hide forwards;
  animation-iteration-count: 1;
}

.search-bar.close {
  animation: 1s linear search-bar-hide forwards;
  animation-iteration-count: 1;
}

.search-bar.open {
  animation: 1s linear search-bar-show;
  animation-iteration-count: 1;
}

.faceted-search-desktop {
  box-sizing: border-box;
  box-shadow: none;
  width: fit-content;

  &__search-icon {
    bottom: 0.7rem;
  }
}

.search-bar-desktop {
  box-sizing: border-box;

  &__background {
    width: 100%;
    border-radius: $border-radius-4;
    background-color: $andes-white;
    box-shadow: 0 6px $andes-spacing-16 $andes-gray-100;
  }

  &__saved-searches {
    display: inline-block;
    margin-left: $andes-spacing-16;
    padding-left: $andes-spacing-16;
    border-left: 1px solid $andes-gray-070-solid;
  }

  .faceted-search-desktop__main-container {
    margin-bottom: $andes-spacing-8;
  }

  &__alternative-search {
    line-height: $line-height-s;
    font-size: $font-size-14;
  }

  &__alternative-search-button {
    cursor: pointer;

    &:hover {
      color: $andes-blue-600;
    }

    &--highlighted {
      color: $andes-blue-500;
    }
  }
}

@import '../../../styles/mixins';
@import '../../../styles/variables';
@import './home';
@import '../../commons/section/desktop';
@import './exhibitor/desktop';
@import '../../commons/item/desktop';
@import '../../commons/recommendations/desktop';
@import '@andes/modal/base';
@import '@andes/modal/lib/styles/small/index';
@import '@andes/modal/lib/styles/small/actions';
@import '@andes/modal/lib/styles/large/index';
@import '@andes/modal/lib/styles/large/actions';
@import '@andes/checkbox/base';
@import '@andes/dropdown/base';
@import '@andes/floating-menu/base';
@import '@andes/list/index';
@import '@andes/dropdown/lib/styles/standalone-compact';
@import '@andes/form/base';
@import '@andes/textfield/base';
@import '@andes/autocomplete/index';
@import '@andes/snackbar/index';
@import '@andes/tooltip/index';
@import '@andes/common/lib/styles/visually-hidden';
@import './advertising-billboard/desktop';
@import './discovery/desktop';
@import '../../commons/squared-item/desktop';
@import './cities/desktop';
@import './navigation/desktop';
@import './search-bar/desktop';
@import './search-bar/search-by-code/desktop';
@import './most-searched/desktop';
@import './publish-with-us/desktop';
@import '../../commons/empty-screen/empty-screen';
@import './official-stores/desktop';
@import '~@andes/carousel-snapped/index';

.home {
  .row {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;

    &.center {
      justify-content: center;
    }

    h3 {
      margin-bottom: $section-separator;
      margin-top: $section-separator;
    }

    .title {
      color: #9c9c9c;
      font-size: 26px;
      font-weight: 200;
      margin-left: 6px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // To temporary fix relative position div
  .cities,
  .navigation {
    .carousel-container {
      position: static;
    }
  }

  .exhibitor .slick-dots {
    bottom: 88px;
  }

  // fix position item into slider https://github.com/mercadolibre/fury_home-heimdall/pull/506
  .carousel-container {
    .slick-track {
      display: flex;
    }
  }
}

.modal {
  max-width: 840px;
  max-height: 88%;
}

.ui-item {
  &__price-block {
    overflow: hidden;
  }

  &__price {
    color: rgb(0 0 0 / 80%);
    font-size: 24px;
    line-height: 1.2;
  }

  &__discount {
    &-price {
      font-size: 14px;
      line-height: 1.5;
      color: #999;
      position: absolute;
      top: 8px;
      left: 25px;
    }

    &-text {
      font-size: 14px;
      vertical-align: 3px;
      padding-left: 10px;
      color: #39b54a;
    }
  }

  &__top-title,
  &__attributes {
    display: block;
    font-size: 14px;
  }

  &__top-title::after,
  &__attributes::after {
    content: '\200b'; // takes up height even when empty
  }
}

$city-hover-background: #3483fa;
$filler-color: #eae6e6;

.cities {
  .city,
  .filler {
    display: block;
  }

  .city {
    background-color: #fff;
    box-sizing: border-box;
    color: #333;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
}

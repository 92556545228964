@import '@andes/snackbar/index';

.search-by-code {
  z-index: 1;

  &__container {
    display: flex;
    flex-direction: column;
    background: $andes-white;
    box-shadow: 0 6px 16px rgba($andes-black, 10%);
    border-radius: $border-radius-4;
    padding: $andes-spacing-24 $andes-spacing-32;
  }

  &__box {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: $andes-spacing-16;
  }

  &__textfield {
    flex-grow: 1;
    padding: 0;
    margin-right: $andes-spacing-24;

    .andes-form-control__bottom {
      display: none;
    }
  }
}

.navigation {
  $slide-size: 156px;
  $slide-shadow: 22px;
  $slide-offset: 58px;
  $slide-extended-bottom: 122px;
  $expanded-container: $slide-size + $slide-offset + $slide-shadow + $slide-extended-bottom;
  $title-padding: 12px;
  $dot-color: #bbb;
  $cross-color: #999;
  $cross-highlight: #f84b57;

  margin-bottom: 30px;

  .section-header {
    margin-bottom: 22px;
  }

  .carousel-container {
    .prev-button,
    .next-button {
      top: 203px;
    }
  }

  .container {
    h2 {
      margin-bottom: 0;
    }
  }

  .slick-list {
    width: 1203px; // so the close button's shadow doesn't get cut.
    height: 255px;
  }

  .row.container {
    margin-bottom: -($slide-shadow + $slide-offset);
  }

  .view-history {
    z-index: 1;
  }

  .squared-item {
    width: $slide-size;
    height: auto;
    position: absolute;
    top: 27px;

    &__wrapper {
      margin: -19px 7px 92px;
      width: $slide-size;

      a {
        text-decoration: none;
      }
    }

    &.lazy-load,
    &__wrapper.lazy-load {
      height: $slide-size;
    }

    &__content {
      border-top: 0;
      height: 0;
      padding: 0 $title-padding;
      display: block;
      transition: none $transition-duration $transition-timing-function;
      transition-property: height, padding-top;
    }

    &__title {
      margin-top: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity $transition-duration $transition-timing-function;

      // No delay to be hidden during collapsing.
      transition: visibility 0s;
    }
  }

  // The icon container expands the mouse area for accessibility purposes.
  .cross-container {
    position: absolute;
    width: 40px;
    height: 40px;
    right: -13px;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 25;
    box-shadow: none;

    &:hover {
      .cross-circle {
        background-color: $cross-highlight;
      }
    }
  }

  .cross-circle {
    box-sizing: border-box;
    display: none;
    width: 16px;
    height: 16px;
    color: $andes-white;
    font-size: 12px;
    background-color: #999;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
    border-radius: 50%;
    text-align: center;
  }

  .navigation-slide {
    position: relative;
    visibility: visible;
    opacity: 1;

    &.hide {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.2s, opacity 0.2s linear;
    }
  }

  // Hover on the slide or the delete-button keeps the slide expanded.
  .navigation-slide:hover {
    .squared-item {
      @include box-hover-shadow;

      z-index: 1;

      &__wrapper {
        a {
          overflow: hidden;
        }

        a img {
          border-radius: 0;
        }
      }

      &__content {
        padding: $title-padding;
        height: 59px;
        border-top: 1px solid rgb(51 51 51 / 10%);
      }

      &__title {
        opacity: 1;
        visibility: visible;
        margin-top: -2px;

        // Delay 0.1 seconds before becoming visible.
        transition: visibility $transition-duration $transition-visibility-delay;
      }
    }

    .cross-circle {
      display: block;
    }
  }

  .next-button,
  .prev-button {
    top: 127px;
  }
}

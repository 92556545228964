.advice-item {
  display: inline-block;
  height: 326px;
  width: 284px;
  cursor: pointer;
  text-decoration: none;

  &__wrapper {
    overflow: hidden;
    margin: 0 8px 16px;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);

    &:hover {
      // @include box-hover-shadow();
      box-shadow: 0 7px 7px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
    }
  }

  &__image {
    max-width: 100%;
    height: 150px;
  }

  &__content {
    max-width: 100%;
    padding: 24px;

    span,
    h3,
    p {
      display: block;
      max-width: 100%;
      color: rgb(0 0 0 / 80%);
    }
  }

  &__title {
    height: 42px;
    font-size: 18px;
    line-height: 21px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &__description {
    margin-top: 14px;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
}

.advice-tablet .advice-item {
  &__wrapper {
    overflow: hidden;
    margin: 0;
  }
}

.advice-mobile .advice-item {
  display: inline-block;
  height: 267px;
  width: 328px;
  cursor: pointer;

  &__wrapper {
    overflow: hidden;
    margin: 0;
  }

  &__image {
    height: 174px;
  }

  &__content {
    max-width: 100%;
    padding: 24px;
  }

  &__title {
    max-height: 38px;
    max-width: 100%;
    overflow: hidden;
    margin-top: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.19;
  }

  &__description {
    display: none;
  }
}

/* override of ui-library styles for new PI home */

.ui-item__title {
  color: rgb(0 0 0 / 80%) !important;
  font-size: 12px !important;
  font-weight: normal !important;
  margin-top: 1px !important;
}

.ui-item__attributes {
  font-weight: 300 !important;
}

// TODO: delete after future changes in home
.home {
  overflow: hidden;
  margin-bottom: -64px;
}

body {
  background-color: $home-background;
}

.grecaptcha-badge {
  display: none;
}

.most-searched_container {
  position: relative;
  display: flex;
  margin-left: 8px;
  justify-content: space-between;

  ul {
    list-style-type: none;
    padding-left: 0;
    padding-right: 10px;

    li {
      font-size: 13px;
      height: 22px;

      &.most-searched__category-title {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #333;
        margin-bottom: 12px;
        height: 38px;
        width: 154px;

        &:last-child {
          max-width: 198px;
        }
      }

      a {
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #999;
        text-decoration: none;
      }
    }
  }
}

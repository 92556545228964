@import './section';

section {
  > .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0;

    @media (width <= 769px) {
      margin: 0 20px;
    }
  }

  .section-header {
    margin-bottom: 22px;

    h2 {
      display: inline;
      font-size: 24px;
      font-weight: 300;
      margin: 0 0 0 8px;
    }

    a,
    .ui-link {
      font-size: 14px;
      margin-bottom: 2px;

      &:hover {
        color: $link-hover;
      }
    }
  }
}

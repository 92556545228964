.empty-state-title {
  h2 {
    text-align: center;
    display: block;
  }

  a {
    margin: 8px;
    text-align: center;
    display: block;
  }
}

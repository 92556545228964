@import '@andes/button/base';
@import '@andes/button/lib/styles/modifier/loud';
@import '@andes/button/lib/styles/modifier/transparent';
@import '@andes/card/index';
@import '../../commons/advertisement/advertisement';
@import '../../commons/advice-item/advice-item';
@import '../../../styles/common';
@import '../../../styles/new-pi-overrides';
@import './advice/index';

main {
  background-color: $home-background;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
}

.home {
  overflow-x: hidden;

  /* flexbox grid */
  .row {
    position: relative;
  }
}

.modal {
  iframe {
    width: 100%;
    border: 0;
    -webkit-overflow-scrolling: touch;
  }
}

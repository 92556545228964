.discovery {
  &__body {
    display: flex;
    padding-left: 10px;
  }

  .advertising {
    max-width: 100%;
    height: 250px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    text-decoration: none;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);

    &__image {
      height: 100%;
      width: auto;
      padding-bottom: 44px;

      img {
        height: 100%;
        float: right;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__info__title {
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 4px;
      color: rgb(0 0 0 / 80%);
    }

    &__info__text {
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      text-transform: uppercase;
      letter-spacing: normal;
      color: #4b4b4b;
    }

    &__info__text--bold {
      font-weight: 600;
      font-size: 28px;
      color: rgb(0 0 0 / 80%);
    }

    &__info__button {
      border-radius: 2px;
      padding: 9px 10px;
      margin-top: 15px;
      font-weight: 400;
    }
  }
}
